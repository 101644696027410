/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Accueil",
    slug: "dashboard",
    icon: "HomeIcon",
    isDisabledAdmin: false,
  },
  {
		url: "/users",
		name: "Administrateurs",
		slug: "Users",
    icon: "UsersIcon",
    isDisabledAdmin: true,
  },
  {
		url: "/brokers",
		name: "Intermédiaires",
		slug: "Brokers",
    icon: "UsersIcon",
    isDisabledAdmin: false,
  },
  {
    url: "/customer-account",
    name: "Comptes clients",
    slug: "CustomerAccount",
    icon: "CommandIcon",
    isDisabledAdmin: false,
  },
  {
    url: "/ongoing-project",
    name: "Projets en cours",
    slug: "OngoingProject",
    icon: "PlayIcon",
    isDisabledAdmin: false,
  },
  {
    name: "Souscriptions",
    icon: "BookOpenIcon",
    submenu: [
      {
        url: "/subscribe-abq",
        name: "ABQ",
        slug: "Subscribe",
        icon: "BookOpenIcon",
      },
      {
        url: "/subscribe-apf",
        name: "APF",
        slug: "Subscribe",
        icon: "BookOpenIcon",
      },
      {
        url: "/subscribe-aaf",
        name: "AAF",
        slug: "Subscribe",
        icon: "BookOpenIcon",
      },
      {
        url: "/subscribe-aae",
        name: "AAE",
        slug: "Subscribe",
        icon: "BookOpenIcon",
      },
      {
        url: "/subscribe-ahr",
        name: "AHR",
        slug: "Subscribe",
        icon: "BookOpenIcon",
      },

    ],
    slug: "Subscribe",
    isDisabledAdmin: false,
  },
  /*
  {
		url: "/mailing",
		name: "Mailing",
		slug: "Mailing",
    icon: "MailIcon",
    isDisabledAdmin: true,
  },
  */
  {
    name: "Mails Clients",
    icon: "MailIcon",
    submenu: [
      {
        url: "/mail-relaunch-current-project",
        name: "Relance Projet en Cours",
        slug: "CustomerEmail",
        icon: "MailIcon",
      },
      {
        url: "/mail-additional-info",
        name: "Infos Complémentaires pour Intermédiaire",
        slug: "CustomerEmail",
        icon: "MailIcon",
      },
      {
        url: "/mail-default-customers-email",
        name: "Devis par Défaut par Défaut pour Clients",
        slug: "CustomerEmail",
        icon: "MailIcon",
      },
      /*{
        url: "/mail-subscription",
        name: "Souscription",
        slug: "CustomerEmail",
        icon: "MailIcon",
      },*/
      {
        url: "/mail-default-quote",
        name: "Devis par Défaut pour Intermédiaires",
        slug: "CustomerEmail",
        icon: "MailIcon",
      },
      {
        url: "/mail-call-contribution",
        name: "Appel de Cotisation",
        slug: "CustomerEmail",
        icon: "MailIcon",
      },
      {
        url: "/mail-payement-reminder",
        name: "Relance Paiement",
        slug: "CustomerEmail",
        icon: "MailIcon",
      },
    ],
    slug: "CustomerEmail",
    isDisabledAdmin: false,
  },
  {
    url: "/slips",
    name: "Bordereaux",
    slug: "Slips",
    icon: "LayersIcon",
    isDisabledAdmin: false,
  },
  {
    url: "/customer-document",
    name: "Documents Clients",
    slug: "CustomerDocument",
    icon: "FolderIcon",
    isDisabledAdmin: false,
  },
  {
		url: "/ticket",
		name: "Tickets",
		slug: "Tickets",
    icon: "BookmarkIcon",
    isDisabledAdmin: false,
  },
  {
    url: "/policy",
    name: "Politiques juridiques",
    slug: "Policy",
    icon: "SunIcon",
    isDisabledAdmin: true,
  },
]
